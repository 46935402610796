var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.esperar),expression:"!esperar"}],attrs:{"justify":"end"}},[(_vm.canAccessCRUD && _vm.listaNotasDebito.length)?_c('v-menu',{ref:"data_contabilizacao_todos",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"id":"btn_salvar","color":"primary","disabled":!_vm.canAccessCRUD}},Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("event")]),_vm._v(" "+_vm._s(_vm.$t('label.definir_data'))+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.definir_todas_data_contabilizacao')))])])]}}],null,false,3275814635),model:{value:(_vm.menuAteraDataSelecionados),callback:function ($$v) {_vm.menuAteraDataSelecionados=$$v},expression:"menuAteraDataSelecionados"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","color":"primary","scrollable":""},model:{value:(_vm.dataContabilizacaoAll),callback:function ($$v) {_vm.dataContabilizacaoAll=$$v},expression:"dataContabilizacaoAll"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},nativeOn:{"click":function($event){_vm.menuAteraDataSelecionados = false}}},[_vm._v(" "+_vm._s(_vm.$t('label.cancelar'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},nativeOn:{"click":function($event){return _vm.aplicarDataNotasSelecionadas.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('label.aplicar'))+" ")])],1)],1):_vm._e()],1),_c('v-container',{staticClass:"Container_Tabela",attrs:{"fluid":"","px-0":"","py-2":""}},[_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.esperar),expression:"!esperar"}],staticClass:"elevation-1",attrs:{"id":"tabela_notas_debito","headers":_vm.headers,"show-select":"","items":_vm.listaNotasDebito,"options":_vm.pagination,"server-items-length":_vm.totalPage,"item-key":"idExterno","no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
            itemsPerPageOptions: [10, 25, 50],
          }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.id_externo",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.idExterno)+" ")]}},{key:"item.nome_fornecedor",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomeFornecedor)+" ")]}},{key:"item.valor_nota_debito",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.valorNotaDebito))+" ")]}},{key:"item.data_emissao",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataEmissao)+" ")]}},{key:"item.data_vencimento",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataVencimento)+" ")]}},{key:"item.data_contabilizacao",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{key:item.id,ref:"data_contabilizacao",attrs:{"disabled":!_vm.canAccessCRUD,"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"CalendarioForm-data__text",attrs:{"id":("tabela_notas_debito_data_" + (item.idExterno)),"disabled":!_vm.canAccessCRUD,"readonly":"","clearable":"","prepend-icon":"event"},model:{value:(item.dataFormatada),callback:function ($$v) {_vm.$set(item, "dataFormatada", $$v)},expression:"item.dataFormatada"}},on))]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","color":"primary","scrollable":""},on:{"input":function($event){return _vm.setaDataFormatada(item)}},model:{value:(item.dataContabilizacao),callback:function ($$v) {_vm.$set(item, "dataContabilizacao", $$v)},expression:"item.dataContabilizacao"}})],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.esperar),expression:"!esperar"}],attrs:{"justify":"end"}},[(_vm.canAccessCRUD && _vm.listaNotasDebito.length)?_c('v-btn',{staticClass:"mr-3",attrs:{"right":"","id":"btn_salvar","color":"primary"},nativeOn:{"click":function($event){return _vm.openSaveDialog('alteraNotaUnica')}}},[_vm._v(" "+_vm._s(_vm.$t('label.salvar'))+" ")]):_vm._e()],1),_c('confirm',{ref:"confirmDialog",attrs:{"message":_vm.$t('message.deseja_salvar', { text: this.$tc('title.nota_debito', 1).toLowerCase() })},on:{"agree":_vm.salvar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }